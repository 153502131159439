
function plusMinus(el) {
    el.querySelector(".plus").addEventListener('click', function (e) {
        e.preventDefault();
        let nr = parseInt(el.querySelector('.qty__input').value) + 1;
        el.querySelector('.qty__input').value = nr;

    })
    el.querySelector(".min").addEventListener('click', function (e) {
        e.preventDefault();
        let nr = parseInt(el.querySelector('.qty__input').value) - 1;
        if (nr > 0) {
            el.querySelector('.qty__input').value = nr
        }
    })
}

if (document.querySelector(".p-card__form .qty")) {
    let qtyBtn = document.querySelectorAll(".qty");
    for (i = 0; i < qtyBtn.length; ++i) {
        plusMinus(qtyBtn[i]);
    }
    document.querySelector(".qty__input").addEventListener("change", function () {
        if (this.value <= 0 || isNaN(this.value)) {
            this.value = 1
        } else {
        }
    })
    if (document.querySelector('.qty__input')) {
        var regExpMask2 = IMask(
            document.querySelector('.qty__input'), {
                mask: Number
            });
    }
}
